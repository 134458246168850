import React from "react"
import Layout from "@components/layout";

// Import SVG
import sdgs_img00 from '../images/sdgs_img00.webp';
import sdgs_img01 from '../images/sdgs_img01.webp';
import sdgs_img02 from '../images/sdgs_img02.webp';
import sdgs_img03 from '../images/sdgs_img03.webp';
import sdgs_img04 from '../images/sdgs_img04.webp';

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Sdgs = () => {
  const linksdgs1 = 'https://www.jinken-library.jp/my-jinken/';
  const linksdgs2 = 'https://www.mofa.go.jp/mofaj/gaiko/oda/sdgs/index.html';
  const linksdgs3 = 'https://www.unic.or.jp/activities/economic_social_development/sustainable_development/2030agenda/';
  const linksdgs4 = 'https://www.unicef.or.jp/kodomo/sdgs/';

  // Seo情報設定
  const pathName = 'sdgs';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div>
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">SDGsへの取組方針</h1>
        </div>
        <div>
          <div className="max-w-xl mx-auto  md:max-w-5xl px-6 ">
            <p className="mt-[30px]"><img src={sdgs_img00} alt=""/></p>
            <p className="text-[13px] md:text-center">SDGs（Sustainable Development Goals・持続可能な開発目標）とは、
            2015年に国連サミットで採択された2030年までの国際社会全体として掲げている目標です。<br/>
            持続な世界の実現に向けて、SDGsでは合計17ゴールが設定されています。</p>
            <div className="text-center mb-[50px] md:mt-[80px] mt-[60px] "> 
              <h2 className="font-semibold tracking-wide text-lg md:text-[17px]">Initiatives for SDGs</h2>
  
            </div>
            <p className="text-[13px] md:text-center md:mb-[130px] mb-[80px]  mt-[50px]">株式会社Enzineでは、事業を通じて持続可能な社会の実現に取り組んでおります。
            </p>

            <div className="flex flex-wrap justify-around">
              <div className="md:w-2/4 md:max-w-[430px] mb-[80px] ">
                <h3 className="border-l border-[#0A61E7] font-semibold mb-10 pl-2 text-[14px] ">シェンダー平等を実現しよう</h3>
                <p className="mx-auto max-w-[180px]"><img src={sdgs_img01} alt=""/></p>
                <p className="text-[13px] mt-5">弊社では、ジェンダーはもちろん年齢やその人の個性を尊重しています。<br/>
                法務省が取り組みを進めている「<a href={linksdgs1} target="_blank" rel="noopener noreferrer">Myじんけん宣言</a>」にも賛同し、社内外問わずジェンダー平等を訴えています。
                </p>
              </div>

              <div className="md:w-2/4 md:max-w-[430px] mb-[80px] ">
                <h3 className="border-l border-[#0A61E7] font-semibold mb-10 pl-2 text-[14px] ">働きがいも経済成長も</h3>
                <p className="mx-auto max-w-[180px]"><img src={sdgs_img02} alt=""/></p>
                <p className="text-[13px] mt-5">弊社は、主に自社インターネットメディアの構築及び運営を行っています。<br/>
                社員ひとりひとりがやりがいを感じられる職場づくりに力を入れています。<br/>また、社員のライフスタイルに合わせリモートワークの導入しております。
                </p>
              </div>

              <div className="md:w-2/4 md:max-w-[430px] mb-[80px] ">
                <h3 className="border-l border-[#0A61E7] font-semibold mb-10 pl-2 text-[14px] ">気候変動に具体的な対策を</h3>
                <p className="mx-auto max-w-[180px]"><img src={sdgs_img03} alt=""/></p>
                <p className="text-[13px] mt-5">弊地球温暖化対策や環境保護の目的で、弊社では社員ひとりひとりが小さいながらも具体的なアクションを起こしています。<br/>
                社内業務をクラウドで統一し、ペーパーレス化を図る。<br/>
                オフィスの空調の適切な温度調整<br/>
                社内業務をクラウドで統一し、ペーパーレス化<br/>
                社内で発生したゴミの分別とリサイクル。<br/>
                リモートワークの導入することで通勤にかかる移動手段による必要以上のCO2の排出を抑えています。
                </p>
              </div>

              <div className="md:w-2/4 md:max-w-[430px] mb-[80px] ">
                <h3 className="border-l border-[#0A61E7] font-semibold mb-10 pl-2 text-[14px] ">パートナーシップで目標を達成しよう</h3>
                <p className="mx-auto max-w-[180px]"><img src={sdgs_img04} alt=""/></p>
                <p className="text-[13px] mt-5">社員はもちろん取引先も含めてSDGsの達成を目指しています。<br/>
                積極的なリモート会議の導入によるCo2の削減、紙の資料を削減するペーパーレス化などを行っております。
                </p>
              </div>
            </div>
            <div className="text-center mb-[50px] md:mt-[30px] mt-[20px] "> 
              <h2 className="font-semibold tracking-wide text-lg md:text-[17px]">SDGs reference site</h2>
            </div>
            <div className="max-w-[200px] mx-auto mb-[80px]">
              <ul>
              <li><a href={linksdgs2} target="_blank" rel="noopener noreferrer">外務省ホームページ</a></li>
                <li><a href={linksdgs3} target="_blank" rel="noopener noreferrer">国際連合広報センター</a></li>
                <li><a href={linksdgs4} target="_blank" rel="noopener noreferrer">日本ユニセフホームページ</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default Sdgs


